/* eslint-disable no-console */
import PropTypes from 'prop-types'
import React, { useRef, useEffect } from 'react'
import { IoTelescopeOutline } from 'react-icons/io5'

VoyagerPlugin.propTypes = {
  toggle_voyager: PropTypes.func.isRequired,
}

function VoyagerPlugin(props) {
  useEffect(() => {
    props.toggle_voyager()
  }, [])

  return <div />
}

export function useVoyagerPlugin(props) {
  const propsRef = useRef(props)
  propsRef.current = props

  const pluginRef = useRef()
  pluginRef.current = pluginRef.current || {
    title: 'Voyager',
    icon: () => <IoTelescopeOutline />,
    content: () => <VoyagerPlugin {...propsRef.current} />,
  }
  return pluginRef.current
}
