/* eslint-disable import/no-named-as-default */
import { useExplorerPlugin } from '@graphiql/plugin-explorer'
import { GraphiQL } from 'graphiql'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import * as adminExampleQueries from './example_queries/admin_example_queries'
import * as exampleQueries from './example_queries/examples_queries'
import { useExamplePlugin } from './plugins/example'
import { useLogoutPlugin } from './plugins/logout'
import { useUserGuidePlugin } from './plugins/userguide'
import { useVoyagerPlugin } from './plugins/voyager'

import 'graphiql/graphiql.min.css'
import '@graphiql/plugin-explorer/dist/style.css'
import './plugins/overrides.css'

const DEFAULT_PLUGIN = 'GraphiQL Explorer'
const DEFAULT_TAB = { query: '# Write your query here\n' }

GraphQLViewer.propTypes = {
  fetcher: PropTypes.func.isRequired,
  toggle_voyager: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

function GraphQLViewer({ fetcher, toggle_voyager, logout, username }) {
  const [query, setQuery] = useState()
  const [plugin, setPlugin] = useState(DEFAULT_PLUGIN)

  const isAdmin = username.includes('edp-')
  const examples = isAdmin ? { ...adminExampleQueries, ...exampleQueries } : exampleQueries

  const explorerPlugin = useExplorerPlugin({ query, onEdit: setQuery })
  const examplePlugin = useExamplePlugin({ examples, setQuery })
  const voyagerPlugin = useVoyagerPlugin({ toggle_voyager })
  const userGuidePlugin = useUserGuidePlugin({ setPlugin })
  const logoutPlugin = useLogoutPlugin({ logout })

  const logo = process.env.REACT_APP_ENV || 'EDP'

  return (
    <GraphiQL
      fetcher={fetcher}
      query={query}
      onEditQuery={setQuery}
      visiblePlugin={plugin}
      onTogglePluginVisibility={(visiblePlugin) => setPlugin(visiblePlugin)}
      defaultTabs={[DEFAULT_TAB]}
      plugins={[explorerPlugin, examplePlugin, voyagerPlugin, userGuidePlugin, logoutPlugin]}
    >
      <GraphiQL.Logo>{logo.toUpperCase()}</GraphiQL.Logo>
    </GraphiQL>
  )
}

export default GraphQLViewer
