import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { HiOutlineDocumentText } from 'react-icons/hi'

UserGuidePlugin.propTypes = {
  setPlugin: PropTypes.func.isRequired,
}

function UserGuidePlugin(props) {
  useEffect(() => {
    window.open('https://wiki.guaranteedrate.com/display/PLAT/User+Guide+for+EDP', '_blank')
    props.setPlugin('GraphiQL Explorer')
  }, [])

  return <div />
}

export function useUserGuidePlugin(props = {}) {
  const propsRef = useRef(props)
  propsRef.current = props

  const pluginRef = useRef()
  pluginRef.current = pluginRef.current || {
    title: 'User Guide',
    icon: () => <HiOutlineDocumentText />,
    content: () => <UserGuidePlugin {...propsRef.current} />,
  }
  return pluginRef.current
}
