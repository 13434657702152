import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { BiLogOut } from 'react-icons/bi'

LogoutPlugin.propTypes = {
  logout: PropTypes.func.isRequired,
}

function LogoutPlugin(props) {
  useEffect(() => {
    props.logout()
  }, [])

  return <div />
}

export function useLogoutPlugin(props) {
  const propsRef = useRef(props)
  propsRef.current = props

  const pluginRef = useRef()
  pluginRef.current = pluginRef.current || {
    title: 'Logout',
    icon: () => <BiLogOut />,
    content: () => <LogoutPlugin {...propsRef.current} />,
  }
  return pluginRef.current
}
