import { Voyager } from 'graphql-voyager'
import PropTypes from 'prop-types'
import React from 'react'

import 'graphiql/graphiql.min.css'
import 'graphql-voyager/dist/voyager.css'

VoyagerViewer.propTypes = {
  toggle_voyager: PropTypes.func.isRequired,
  schema_query: PropTypes.string,
}

function VoyagerViewer(props) {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
        <button label="Back" title="Back to GraphiQL" onClick={props.toggle_voyager}>
          Back to GraphiQL
        </button>
      </div>
      <Voyager
        introspection={props.schema_query}
        // eslint-disable-next-line node/no-process-env
        workerURI={process.env.PUBLIC_URL + '/voyager.worker.js'}
      />
    </div>
  )
}

export default VoyagerViewer
