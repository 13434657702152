// indented the query for example viewer, otherwise indent all the way to the left
const general_personnel_query = /* GraphQL */ `
          id
          company_id
          first_name
          last_name
          preferred_name
          start_date
          termination_date
          business_title
          
          otherIds {
            items {
              id
              type
              other_id
            }
          }
`

export const admin_basic_personnel = /* GraphQL */ `
  query BasicPersonnel {
    getPersonnel(id: "test") {
      ${general_personnel_query}
    }
  }
`

export const admin_list_by_ceridian_id = /* GraphQL */ `
  query ListPersonnelByCeridianID {
    listPersonnelsByOtherID(other_id: "test", type: { eq: "ceridian" }) {
      items {
        personnel {
          ${general_personnel_query}
        }
      }
    }
  }
`

export const admin_list_by_legacy_id = /* GraphQL */ `
  query ListPersonnelByLegacyID {
    listPersonnelsByOtherID(other_id: "test", type: { eq: "SPOT ID" }) {
      items {
        personnel {
          ${general_personnel_query}
        }
      }
    }
  }
`

export const admin_list_by_email = /* GraphQL */ `
  query ListPersonnelByEmail {
    listPersonnelByEmail(email: "test@rate.com") {
      items {
        personnel {
          ${general_personnel_query}
        }
      }
    }
  }
`

export const admin_list_by_last_name = /* GraphQL */ `
  query ListPersonnelByLastName {
    listPersonnelsByLastName(last_name: "Test") {
      items {
        ${general_personnel_query}
      }
    }
  }
`
