import startcase from 'lodash.startcase'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { FaRegLightbulb } from 'react-icons/fa'

function remove_prettified_spaces(query) {
  return query
    .split('\n')
    .map((line) => (line.startsWith('  ') ? line.slice(2) : line))
    .join('\n')
}

ExamplePlugin.propTypes = {
  examples: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
}

function ExamplePlugin(props) {
  const exampleQueryButtons = Object.keys(props.examples).map((name) => (
    <li key={name} className="graphiql-history-item">
      <button
        className="graphiql-un-styled graphiql-history-item-label"
        onClick={() => props.setQuery(remove_prettified_spaces(props.examples[name]))}
      >
        {startcase(name)}
      </button>
    </li>
  ))

  return (
    <section aria-label="Example Queries" className="graphiql-history">
      <div className="graphiql-history-header">Example Queries</div>
      <ul className="graphiql-history-items">{exampleQueryButtons}</ul>
    </section>
  )
}

export function useExamplePlugin(props) {
  const propsRef = useRef(props)
  propsRef.current = props

  const pluginRef = useRef()
  pluginRef.current = pluginRef.current || {
    title: 'Examples',
    icon: () => <FaRegLightbulb />,
    content: () => <ExamplePlugin {...propsRef.current} />,
  }
  return pluginRef.current
}
