// indented the query for example viewer, otherwise indent all the way to the left
const general_personnel_query = /* GraphQL */ `
          id
          company_id
          first_name
          last_name
          preferred_name
          start_date
          termination_date
          business_title

          licenses {
            items {
              type
              sub_type
              number
            }
          }
          
          legacy_id: otherIds(type: {eq: "SPOT ID"}) {
            items {
              other_id
            }
          }

          ceridian_id: otherIds(type: {eq: "ceridian"}) {
            items {
              other_id
            }
          }

          encompass_id: otherIds(type: {eq: "encompass"}) {
            items {
              other_id
            }
          }

          nmls_id: otherIds(type: {eq: "nmls"}) {
            items {
              other_id
            }
          }
          
          # by using type the query is more efficient
          work_email: emailAddresses(type: { eq: "work" }) {
            items {
              email
            }
          }

          # by using type the query is more efficient
          work_phone: phoneNumbers(type: { eq: "business" }) {
            items {
              number
            }
          }

          department: companyOrgGroups(typeName: { beginsWith: { type: department } }) {
            items {
              name
            }
          }

          division: companyOrgGroups(typeName: { beginsWith: { type: division } }) {
            items {
              name
            }
          }

          # a person can have more than one work assignment
          #  - use is_primary being true if only want to use one from the array
          workAssignments {
            items {
              accounting_code
              is_primary
              job_function
            }
          }
`

export const basic_personnel = /* GraphQL */ `
  query BasicPersonnel {
    # This gets a person based on EDP's UUID which is unique across companies
    getPersonnel(id: "test") {
      ${general_personnel_query}
    }
  }
`

export const list_by_ceridian_id = /* GraphQL */ `
  query ListPersonnelByCeridianID {
    # This gets a person based on ceridian's ID
    listPersonnelsByOtherID(other_id: "test", type: { eq: "ceridian" }) {
      items {
        personnel {
          ${general_personnel_query}
        }
      }
    }
  }
`

export const list_by_legacy_id = /* GraphQL */ `
  query ListPersonnelByLegacyID {
    # This gets a person based on legacy's ID (SPOT for GRA/PRATE, Roster for GRI)
    listPersonnelsByOtherID(other_id: "test", type: { eq: "SPOT ID" }) {
      items {
        personnel {
          ${general_personnel_query}
        }
      }
    }
  }
`

export const list_by_email = /* GraphQL */ `
  query ListPersonnelByEmail {
    # This gets a person based on their primary email address
    # Keep in mind casing is important
    listPersonnelByEmail(email: "test@rate.com") {
      items {
        personnel {
          ${general_personnel_query}
        }
      }
    }
  }
`

export const list_by_last_name = /* GraphQL */ `
  query ListPersonnelByLastName {
    # This gets a person based on their last name
    listPersonnelsByLastName(last_name: "Test") {
      items {
        ${general_personnel_query}
      }
    }
  }
`
