/* eslint-disable import/no-named-as-default */
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Amplify, API, Auth } from 'aws-amplify'
import { getIntrospectionQuery } from 'graphql'
import React, { useEffect, useState, useCallback } from 'react'

// eslint-disable-next-line import/no-unresolved, node/no-missing-import
import '@aws-amplify/ui-react/styles.css'

// this is generated as part of the Amplify build
// eslint-disable-next-line
import awsExports from './aws-exports'

import GraphQLViewer from './GraphQLViewer'
import VoyagerViewer from './VoyagerViewer'

Amplify.configure(awsExports)
Auth.configure(awsExports)

const fetcher = (graphQLParams) => API.graphql(graphQLParams)
const logout = async () => {
  await Auth.signOut()
  window.location.reload()
}

const App = () => {
  const [schema_query, set_schema_query] = useState(null)
  const [voyager_open, set_voyager_open] = useState(false)
  const [username, setUsername] = useState('')

  const toggle_voyager = useCallback(() => set_voyager_open((prev) => !prev), [])
  const getUsername = async () => {
    const userInfo = await Auth.currentUserInfo()
    setUsername(userInfo.username)
  }

  useEffect(() => {
    getUsername()
    fetcher({ query: getIntrospectionQuery() }).then((response) => {
      set_schema_query(response)
    })
  }, [])

  // Display Voyager instead of GraphiQL
  if (voyager_open) {
    return <VoyagerViewer toggle_voyager={toggle_voyager} schema_query={schema_query} />
  }

  return (
    <GraphQLViewer
      fetcher={fetcher}
      schema_query={schema_query}
      logout={logout}
      toggle_voyager={toggle_voyager}
      username={username}
    />
  )
}

export default withAuthenticator(App)
